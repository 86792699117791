import { ServiceFetch } from '../service-fetch'

export default class UserModel extends ServiceFetch {
    checkLoginPos = (data) => this.authFetch({
        url: 'user/checkLoginPos',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getUserBy = (data) => this.authFetch({
        url: 'user/getUserBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getUserByCode = (data) => this.authFetch({
        url: 'user/getUserByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getUserBySecretCode = (data) => this.authFetch({
        url: 'user/getUserBySecretCode',
        method: 'POST',
        body: JSON.stringify(data),
    })
}