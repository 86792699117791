import { ServiceFetch } from '../service-fetch'

export default class BillBreakModel extends ServiceFetch {
    getBillBreakBy = (data) => this.authFetch({
        url: 'bill-break/getBillBreakBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getBillBreakByCode = (data) => this.authFetch({
        url: 'bill-break/getBillBreakByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    clearBillBreakBySaleStation = (data) => this.authFetch({
        url: 'bill-break/clearBillBreakBySaleStation',
        method: 'POST',
        body: JSON.stringify(data),
    })

    updateBillBreakBy = (data) => this.authFetch({
        url: 'bill-break/updateBillBreakBy',
        method: 'POST',
        body: JSON.stringify(data),
    })
    
    insertBillBreak = (data) => this.authFetch({
        url: 'bill-break/insertBillBreak',
        method: 'POST',
        body: JSON.stringify(data),
    })
    
    deleteBillBreakByCode = (data) => this.authFetch({
        url: 'bill-break/deleteBillBreakByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })
}