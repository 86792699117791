import { ServiceFetch } from '../service-fetch'

export default class EmployeeModel extends ServiceFetch {
    getEmployeeBy = (data) => this.authFetch({
        url: 'employee/getEmployeeBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getEmployeeByCode = (data) => this.authFetch({
        url: 'employee/getEmployeeByCode',
        method: 'POST',
        body: JSON.stringify(data),
    }) 
}

