import { createContext } from "react"

const authContext = createContext({
  authcertifying: true,
  authenticated: false,
  permissions: [],
  station_setting: {},
  user: {
    user_code: '',
    user_name: '',
    user_lastname: '',
  },
  _handleLogin: () => { },
  _handleLogout: () => { },
  _initiateAuthentication: () => { },
})

export const AuthProvider = authContext.Provider
export const AuthConsumer = authContext.Consumer