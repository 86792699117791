import { ServiceFetch } from '../service-fetch'

export default class ReceiptRequestCancelModel extends ServiceFetch {
    getReceiptRequestCancelBy = (data) => this.authFetch({
        url: 'receipt-request-cancel/getReceiptRequestCancelBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getReceiptRequestCancelByCode = (data) => this.authFetch({
        url: 'receipt-request-cancel/getReceiptRequestCancelByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    increaseReceiptRequestCancelSelectNoByCode = (data) => this.authFetch({
        url: 'receipt-request-cancel/increaseReceiptRequestCancelSelectNoByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    insertReceiptRequestCancel = (data) => this.authFetch({
        url: 'receipt-request-cancel/insertReceiptRequestCancel',
        method: 'POST',
        body: JSON.stringify(data),
    })
}