import { ServiceFetch } from '../service-fetch'

export default class ProductModel extends ServiceFetch {
    generateProductLastCode = (data) => this.authFetch({
        url: 'product/generateProductLastCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    generateBarcodeLastCode = (data) => this.authFetch({
        url: 'product/generateBarcodeLastCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getProductBy = (data) => this.authFetch({
        url: 'product/getProductBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getProductWholesalePriceByCode = (data) => this.authFetch({
        url: 'product/getProductWholesalePriceByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getProductByRoot = (data) => this.authFetch({
        url: 'product/getProductByRoot',
        method: 'POST',
        body: JSON.stringify(data),
    })
    getProductByList = (data) => this.authFetch({
        url: 'product/getProductByList',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getProductByBarcode = (data) => this.authFetch({
        url: 'product/getProductByBarcode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getProductByCode = (data) => this.authFetch({
        url: 'product/getProductByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    updateProductBy = (data) => this.authFetch({
        url: 'product/updateProductBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    insertProduct = (data) => this.authFetch({
        url: 'product/insertProduct',
        method: 'POST',
        body: JSON.stringify(data),
    })

    deleteProductByCode = (data) => this.authFetch({
        url: 'product/deleteProductByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })
}