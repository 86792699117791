import { ServiceFetch } from '../service-fetch'

export default class PromotionModel extends ServiceFetch {
    getPromotionBy = (data) => this.authFetch({
        url: 'promotion/getPromotionBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getPromotionByCode = (data) => this.authFetch({
        url: 'promotion/getPromotionByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getActivePromotionBy = (data) => this.authFetch({
        url: 'promotion/getActivePromotionBy',
        method: 'POST',
        body: JSON.stringify(data),
        name: "getActivePromotionBy"
    })
}