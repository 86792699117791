import { ServiceFetch } from '../service-fetch'

export default class StockIssueModel extends ServiceFetch {
    generateStockIssueLastCode = (data) => this.authFetch({
        url: 'stock-issue/generateStockIssueLastCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getStockIssueBy = (data) => this.authFetch({
        url: 'stock-issue/getStockIssueBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getStockIssueByCode = (data) => this.authFetch({
        url: 'stock-issue/getStockIssueByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })
    getStockIssueListByCode = (data) => this.authFetch({
        url: 'stock-issue/getStockIssueListByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    updateStockIssueBy = (data) => this.authFetch({
        url: 'stock-issue/updateStockIssueBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    createStock = (data) => this.authFetch({
        url: 'stock-issue/createStock',
        method: 'POST',
        body: JSON.stringify(data),
    })

    insertStockIssue = (data) => this.authFetch({
        url: 'stock-issue/insertStockIssue',
        method: 'POST',
        body: JSON.stringify(data),
    })

    deleteStockIssueByCode = (data) => this.authFetch({
        url: 'stock-issue/deleteStockIssueByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })
}