import { ServiceFetch } from '../service-fetch'

export default class ReceiptPaymentListModel extends ServiceFetch {
    getReceiptPaymentListBy = (data) => this.authFetch({
        url: 'receipt-payment-list/getReceiptPaymentListBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getLastReceiptPaymentListBy = (data) => this.authFetch({
        url: 'receipt-payment-list/getLastReceiptPaymentListBy',
        method: 'POST',
        body: JSON.stringify(data),
    })
}